import { Layout } from 'antd';
import React from 'react';

import Router from './Router';
import HeaderMenu from './HeaderMenu';
import FooterContent from './FooterContent';
import Banner from './Banner';

const { Header, Footer, Content } = Layout;

const MainLayout = props => {
  return (
    <Layout>
      <Banner />
      <Header className="p-0 h-auto">
        <HeaderMenu />
      </Header>
      <Content className="bg-white min-h-screen">
        <Router refetchUser={props.refetchUser} />
      </Content>
      <Footer className="text-center">
        <FooterContent />
      </Footer>
    </Layout>
  );
};

export default MainLayout;
