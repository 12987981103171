import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { Helmet } from 'react-helmet';

const { Title, Paragraph, Text } = Typography;

const About = () => {
  return (
    <Fragment>
      <Helmet>
        <meta name="description" content={`Arcade Hub about page and FAQ`} />
        <title>Arcade Hub - About</title>
        <link rel="canonical" href={`https://arcade-hub.com/about`} />
      </Helmet>
      <Typography className="p-10 lg:px-17">
        <Title>Welcome to Arcade Hub!</Title>
        <Paragraph>
          We built this webapp to help you find suitable games for you in{' '}
          <a
            href="https://www.apple.com/apple-arcade/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Apple Arcade
          </a>{' '}
          and spend your time having fun playing the games instead of searching
          for them!
        </Paragraph>
        <Paragraph>
          You can view all the Apple Arcade games list presented on the platform on the main page
          of the app. There are two view types for the large screens so you can
          choose the one you like best, and you can filter the games by Genres
          and/or sort them by Rating, Release Date, Last Update and Name. Checkout detailed
          game page for more information about the games!
        </Paragraph>
        <Paragraph className="mt-10">
          <Title level={2}>FAQ</Title>
          <Title level={4}>Is the project active?</Title>
          <Text>
          Currently, this project is not in active development (the new games still get added), and we are working on a full-blown video game discovery and tracking platform <a
              href="https://searchtoplay.com/?ref=arcadehub"
              rel="noopener noreferrer"
              target="_blank"
            >
              searchtoplay.com
            </a>.
          </Text>
          <Title level={4}>Where do you take the game scores from?</Title>
          <Text>
            We take the game scores (as well as other data) straight from Apple
            Store in the US region. All scores updated daily.
          </Text>
          <Title level={4}>
            I have a question/bug report/feature request. Where should I address
            it?
          </Title>
          <Text>
            You can send the message to{' '}
            <a
              href="mailto:dmitrii@searchtoplay.com"
              rel="noopener noreferrer"
            >
              dmitrii@searchtoplay.com
            </a>
          </Text>
          <Title level={4}>Can I see the visitor statistics of this app?</Title>
          <Text>
            Sure you can! We love transparency and the OpenStartup movement so
            we made{' '}
            <a
              href="https://simpleanalytics.com/arcade-hub.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              our analytics
            </a>{' '}
            public.
          </Text>
          <Title level={4}>Do you have Public API?</Title>
          <Text>
            <Paragraph>
              We have{' '}
              <b>
                <i>experimental</i>
              </b>{' '}
              Public API access.
            </Paragraph>
            <Paragraph>
              To authentificate you should use HTTP Header and send it with each
              request. Set <code>Authorization</code> header to this token value:
            </Paragraph>
            <Paragraph>
              <pre>
                <code>
                  Bearer
                  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjVlOWE5YTM1ZTdjZGJiMDAzOGQ0NThkOCIsImlhdCI6MTU5NzgxNzEyNX0.2iZb5_q4RDFGd8x1M4io_M2mik51FyDVJknpISMQJ_A
                </code>
              </pre>
            </Paragraph>
            <Paragraph>
              So far we only have one endpoint:{' '}
              <code>GET https://arcade-hub.com/api/game</code> that returns all games data in JSON format.
            </Paragraph>
            <Paragraph>
              In addition, you can use query strings to get a list of values for a specific field:{' '}
              <code>GET https://arcade-hub.com/api/game?field=field_name</code>.
              You can replace <code>field_name</code> with{' '}
              <code>appleId</code>, <code>title</code> or <code>developerName</code>.
            </Paragraph>
          </Text>
        </Paragraph>
      </Typography>
    </Fragment>
  );
};

export default About;


