import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';

const Banner = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const isBannerClosed = localStorage.getItem('bannerClosed');
    if (!isBannerClosed) {
      setVisible(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem('bannerClosed', 'true');
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="flex items-center bg-gray-900 px-6 py-3">
      <div className="flex-1 text-center">
        <p className="text-isabelline text-sm">
          <a
            href="https://searchtoplay.com/?ref=arcadehub"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center"
          >
            Check out our new video game discovery and tracking platform
            <span className="mx-2">—</span>
            <strong className="font-semibold">SearchToPlay.com</strong>
            <span aria-hidden="true" className="text-grayBlue ml-2">
              &rarr;
            </span>
          </a>
        </p>
      </div>
      <div className="ml-4">
        <button
          type="button"
          className="text-isabelline hover:text-grayBlue focus:outline-none"
          onClick={handleClose}
        >
          <span className="sr-only">Dismiss</span>
          <CloseOutlined className="h-5 w-5" />
        </button>
      </div>
    </div>
  );
};

export default Banner;
